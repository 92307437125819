<template>
  <BannerStyle1 :list="bannerList" :position="7"></BannerStyle1>
  <div class="usually-box">
    <section class="special top-box usually-box">
      <h3 class="title">平台特色</h3>
      <ul class="flex-between customize-list-style1" style="padding: 0 20px">
        <li class="shadow-hover" style="width: 20%;" v-for="(item, index) in specitalList" :key="index">
          <img :src="item.img" alt="">
          <div>{{item.name}}</div>
        </li>
      </ul>
    </section>
    <section class="solve-box">
      <h3 class="title">全行业全场景的电商营销解决方案</h3>
      <ul class="solve-list">
        <li v-for="(item, index) in solveList" :key="index">
          <div class="solve-content">
            <h3>{{item.name}}</h3>
            <p>{{item.desc}}</p>
            <img class="back-img" :src="item.img" alt="">
          </div>
        </li>
      </ul>
    </section>
    <section class="shop-box">
      <h3 class="title">全行业全场景的电商营销解决方案</h3>
      <ul class="shop-list">
        <li v-for="(item, index) in shopList" :key="index">
          <img :src="item.img" alt="">
          <h3>{{item.name}}</h3>
          <p>{{item.desc}}</p>
        </li>
      </ul>
    </section>
    <section class="superiority">
      <h3 class="title">平台优势</h3>
      <ul class="flex-between">
        <li class="shadow-hover" v-for="(item, index) in superiorityList" :key="index">
          <img :src="item.img" alt="">
          <h3>{{item.name}}</h3>
          <p>{{item.desc}}</p>
        </li>
      </ul>
    </section>
  </div>
  <section class="custom-box usually-box">
    <h3 class="title">支持定制化开发</h3>
    <ul class="custon-list flex-between">
      <li>
        <h3>标准SAAS版</h3>
        <p>标准化服务，性价比高</p>
        <p>基于有赞云统一开发部署</p>
        <p>企业运营维护成本极低</p>
        <p>适合初创型和中小型企业</p>
      </li>
      <li>
        <h3>专属定制版</h3>
        <p>支持二次定制开发</p>
        <p>满足企业个性化定制需求</p>
        <p>更好地适应商家经营模式</p>
        <p>数据安全性更高</p>
      </li>
    </ul>
  </section>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
const bannerList = [
  {
    img: require("@/assets/product/wisdom/banner.png"),
    title: '智慧电商 SaaS 平台',
    desc: '3分钟搭建分销网络，打开销量增长第二曲线',
    btnText: '预约演示',
    url: '/post/contact',
  }
]

const specitalList = [
  {
    name: '免安装部署',
    img: require('@/assets/product/wisdom/menu1.png'),
  },
  {
    name: '无需购买服务器',
    img: require('@/assets/product/wisdom/menu2.png'),
  },
  {
    name: '终身免费升级',
    img: require('@/assets/product/wisdom/menu3.png'),
  },
  {
    name: '操作简单易上手',
    img: require('@/assets/product/wisdom/menu4.png'),
  },
]

const solveList = [
  {
    name: '社交电商',
    desc: '在社交平台内容种草，粉丝跳转商城直接购买。用口碑传播+裂变工具引爆社交传播，老客带新客，实现爆发式增长。',
    img: require('@/assets/product/wisdom/solve1.png'),
  },
  {
    name: '品牌B2C商城',
    desc: '帮品牌商搭建私域官方旗舰店，融合品牌形象，实现全渠道卖货+多场景营销+智能数据分析，直面消费者，把会员握在自己手中。',
    img: require('@/assets/product/wisdom/solve2.png'),
  },
  {
    name: '直播商城',
    desc: '集预热、互动、购物、裂变营销为一体，既能用视频号等自播卖货，也能嫁接到爱逛等直播平台，边播边卖。',
    img: require('@/assets/product/wisdom/solve3.png'),
  },
  {
    name: '二级分销商城',
    desc: '把会员变成分销员，让分销员自己发展分销员，实现人人分销，利用全体会员李雷，为商城吸粉引流。',
    img: require('@/assets/product/wisdom/solve4.png'),
  },
  {
    name: '同城O2O商城',
    desc: '覆盖烘焙、轻餐茶饮、药店等行业，基于LBS定位覆盖同城，实现小程序下单+同城配送/到店取货/堂食打包等，线上线下一体化经营。',
    img: require('@/assets/product/wisdom/solve5.png'),
  },
  {
    name: '社区团购商城',
    desc: '量身打造团购小程序，让商家轻松管理团长/商品/订单/售后/财务等。省去门店和中间渠道，依托团长社交关系推广卖货，成本低、启动快。',
    img: require('@/assets/product/wisdom/solve6.png'),
  },
]

const shopList = [
  {
    name: '拼团',
    desc: '特价拼团刺激传播老客待新客',
    img: require('@/assets/product/wisdom/scene1.png')
  },
  {
    name: '分销员',
    desc: '粉丝客户帮你卖货老客带新客',
    img: require('@/assets/product/wisdom/scene2.png')
  },
  {
    name: '积分商城',
    desc: '搭建奖励、兑换机制带来更多活跃会员',
    img: require('@/assets/product/wisdom/scene3.png')
  },
  {
    name: '优惠券',
    desc: '满减/打折/兑换券花式营销促销量',
    img: require('@/assets/product/wisdom/scene4.png')
  },
  {
    name: '涨粉神器',
    desc: '先关注再享优惠粉丝轻松涨不停',
    img: require('@/assets/product/wisdom/scene5.png')
  },
  {
    name: '充值赠送',
    desc: '充1000返100追加会员粘性',
    img: require('@/assets/product/wisdom/scene6.png')
  },
  {
    name: '裂变优惠券',
    desc: '支付成功领优惠分享好友带新客',
    img: require('@/assets/product/wisdom/scene7.png')
  },
  {
    name: '预售',
    desc: '提前下单预估销量库存仓储无压力',
    img: require('@/assets/product/wisdom/scene8.png')
  },
  {
    name: '营销游戏',
    desc: '投票/抽奖/摇一摇吸粉引流提活跃',
    img: require('@/assets/product/wisdom/scene9.png')
  },
  {
    name: '智能营销',
    desc: '大数据分析推荐商品智能给出营销建议',
    img: require('@/assets/product/wisdom/scene10.png')
  },
]

const superiorityList = [
  {
    name: '快速部署能力',
    img: require("@/assets/product/wisdom/goodness1.png"),
    desc: '快速部署能力',
  },
  {
    name: '丰富的营销插件',
    img: require("@/assets/product/wisdom/goodness2.png"),
    desc: '拼团、砍价、满减满折、种草笔记等丰富营销方式，快速提升销量',
  },
  {
    name: '精细化会员管理',
    img: require("@/assets/product/wisdom/goodness3.png"),
    desc: '全域会员多渠道统一管理，客户标签化分类分层运营，沉淀会员资产',
  },
  {
    name: '私域运营快速裂变',
    img: require("@/assets/product/wisdom/goodness4.png"),
    desc: '裂变分销快速拓展销售渠道，促进商家业绩增长',
  },
  {
    name: '分销能力',
    img: require("@/assets/product/wisdom/goodness5.png"),
    desc: '解决传统分销的4大难题，提供在线订货、渠道管理、营销推广、交易结算等一站式服务',
  },
  {
    name: '安全能力',
    img: require("@/assets/product/wisdom/goodness6.png"),
    desc: '全域会员多渠道统一管理，客户标签化分类分层运营，沉淀会员资产',
  },
  {
    name: '智能结算能力',
    img: require("@/assets/product/wisdom/goodness7.png"),
    desc: '佣金自动结算，免除繁琐计算，更加精准、高效',
  },
  {
    name: '专业运营指导',
    img: require("@/assets/product/wisdom/goodness8.png"),
    desc: '专业的运营团队和售后服务团队，提供客户项目全生命周期运营指导服务',
  },
]
</script>
<style lang='scss' scoped>
@keyframes borderColor-active {
  to {
    transform: translateX(-50%);
  }
}
.title {
  padding: 94px 0 50px;
  font-size: 42px;
  color: #202020;
  text-align: center;
}
.introduce {
  position: relative;
  z-index: 10;
  padding: 0 60px 100px;
  border-radius: 6px;
  background: #fff;
  > p {
    font-size: 18px;
    line-height: 1.7em;
    color: #808080;
  }
}

.superiority {
  > ul {
    flex-wrap: wrap;
    > li {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      box-sizing: border-box;
      padding: 35px 30px 60px;
      width: 285px;
      height: 360px;
      background-color: #fff;
      > h3 {
        margin: 29px 0 21px;
        width: 100%;
        font-size: 28px;
        font-weight: normal;
        text-align: center;
      }
      > img {
        height: 72px;
      }
      > p {
        font-size: 16px;
        line-height: 1.6em;
        color: #808080;
      }
    }
  }
}
.special {
  padding-bottom: 30px;
  > ul > li > div {
    padding-top: 10px;
    font-size: 20px;
    line-height: 2.5em;
  }
}
.solve-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px;
  background-color: #fff;
  > li {
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-bottom: 18px;
    border: 1px solid #749de5;
    width: 364px;
    height: 229px;
    background-color: #fff;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 200%;
      height: 100%;
      background: linear-gradient(
        115deg,
        #4fcf70,
        #fad648,
        #a767e5,
        #12bcfe,
        #44ce7b
      );
      background-size: 50% 100%;
    }
    &:hover {
      &::before {
        animation: borderColor-active 0.75s linear infinite;
      }
    }
    .solve-content {
      $space: 5px;
      position: absolute;
      top: $space;
      right: $space;
      bottom: $space;
      left: $space;
      z-index: 10;
      box-sizing: border-box;
      padding: 33px 17px 0 26px;
      background-color: #fff;
      > h3 {
        margin-bottom: 18px;
        font-size: 20px;
        font-weight: normal;
        color: #202020;
      }
      > p {
        font-size: 16px;
        line-height: 1.5em;
        color: #808080;
      }
      .back-img {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;
        height: 72px;
      }
    }
  }
}

.shop-list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  background-color: #fff;
  > li {
    box-sizing: border-box;
    padding: 30px 30px 40px;
    width: 20%;
    text-align: center;
    &:hover {
      color: $hoverColor;
      > img {
        filter: hue-rotate(45deg);
      }
      > p {
        color: $hoverColor;
      }
    }
    > img {
      height: 58px;
    }
    > h3 {
      margin: 22px 0 24px;
      font-size: 20px;
      font-weight: normal;
    }
    > p {
      font-size: 16px;
      color: #808080;
    }
  }
}

.custom-box {
  margin-top: 80px;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #1d87d9;
  .title {
    color: #fff;
  }
  .custon-list {
    padding: 0 50px;
    > li {
      padding-bottom: 42px;
      width: 500px;
      background-color: #fff;
      &:last-child {
        > h3 {
          background-color: #f59a23;
        }
      }
      > h3 {
        margin-bottom: 36px;
        height: 84px;
        line-height: 84px;
        font-size: 24px;
        text-align: center;
        color: #fff;
        background-color: #0766e1;
      }
      > p {
        padding-left: 70px;
        font-size: 16px;
        color: #808080;
        line-height: 2em;
        &::before {
          content: "●　";
        }
      }
    }
  }
}
</style>